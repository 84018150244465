import React from "react";
import UpdatePost from "../PostAndCourseManager/UpdatePost";
const AdminUpdatePost = () => {
  return (
    <div>
      <UpdatePost role="admin" />
    </div>
  );
};

export default AdminUpdatePost;
