import React from "react";
import CreatePost from "../PostAndCourseManager/CreatePost";
const ChefCreatePost = () => {
  return (
    <div>
      <CreatePost role="chef" />
    </div>
  );
};

export default ChefCreatePost;
