import React from "react";
import CreatePost from "../PostAndCourseManager/CreatePost";
const AdminCreatePost = () => {
  return (
    <div>
      <CreatePost role="admin" />
    </div>
  );
};

export default AdminCreatePost;
