import React from "react";
import UpdatePost from "../PostAndCourseManager/UpdatePost";
const ChefUpdatePost = () => {
  return (
    <div>
      <UpdatePost role="chef" />
    </div>
  );
};

export default ChefUpdatePost;
